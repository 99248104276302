import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/600.css";
import "@fontsource/open-sans/700.css";
import "@fontsource/montserrat/300.css";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/400-italic.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/700.css";

import "@hotwired/turbo-rails";
import Alpine from "alpinejs";
import focus from "@alpinejs/focus";
import anchor from "@alpinejs/anchor";

import CookieConsent from "../components/CookieConsent";
import "../controllers";
import "../../assets/stylesheets/application.css";

import YesNoControl from "../components/yes_no_control";
import RoleEditor from "../components/RoleEditor";
import LatLngMap from "../components/LatLngMap";
import OtpVerify from "../components/OtpVerify";
import MenuDropdown from "../components/MenuDropdown";
import Modal from "../components/Modal";
import Table from "../components/Table";
import TelephoneField from "../components/TelephoneField";

Alpine.plugin(focus);
Alpine.plugin(anchor);

Alpine.data("yesno", YesNoControl);
Alpine.data("roleeditor", RoleEditor);
Alpine.data("latlngmap", LatLngMap);
Alpine.data("otpverify", OtpVerify);
Alpine.data("modal", Modal);
Alpine.data("table", Table);
Alpine.data("menudropdown", MenuDropdown);
Alpine.data("telephonefield", TelephoneField);

window.Alpine = Alpine;
Alpine.start();

Turbo.StreamActions.redirect = function () {
  Turbo.visit(this.target);
};

window.addEventListener("load", (event) => {
  CookieConsent.showIfRequired();
});
