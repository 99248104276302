import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";

export default () => ({
  init() {
    this.$nextTick(() => {
      const input = this.$refs.input;
      if (input) {
        intlTelInput(input, {
          nationalMode: false,
          hiddenInput: () => ({
            phone: input.getAttribute("name"),
          }),
          loadUtils: () => import("intl-tel-input/utils"),
        });
      }
    });
  },
});
