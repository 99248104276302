import Alpine from "alpinejs";

export default () => ({
  open: false,

  init() {
    Alpine.bind(this.$root, this.root);
  },

  toggle() {
    if (this.open) {
      return this.close();
    }

    this.$refs.button.focus();
    this.open = true;
  },

  close(focusAfter) {
    if (!this.open) {
      return;
    }

    this.open = false;
    focusAfter && focusAfter.focus();
  },

  root: {
    ["x-on:keydown.escape.prevent.stop"]() {
      this.close(this.$refs.button);
    },
    ["x-id"]() {
      return ["dropdown-button"];
    },
  },

  trigger: {
    ["x-ref"]: "button",
    ["@click"]() {
      this.toggle();
    },
    ["x-bind:aria-expanded"]: "open",
    ["x-bind:aria-controls"]() {
      return this.$id("dropdown-button");
    },
  },

  menu: {
    ["x-ref"]: "menu",
    ["x-show"]() {
      return this.open;
    },
    ["x-bind:id"]() {
      return this.$id("dropdown-button");
    },
    ["x-on:click.outside"]() {
      this.close(this.$refs.button);
    },
  },
});
