export default () => ({
  init() {
    const dialog = this.$root;

    this.$nextTick(() => {
      if (dialog) {
        dialog.addEventListener("close", (event) => {
          dialog.remove();
        });

        dialog.showModal();
      }
    });
  },

  close() {
    this.$root.close();
  },
});
