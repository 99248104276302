import { Loader } from "@googlemaps/js-api-loader";

export default (initial) => ({
  lat: 0,
  lng: 0,

  init() {
    this.lat = parseFloat(initial.lat) || 0;
    this.lng = parseFloat(initial.lng) || 0;

    if (this.$refs.map) {
      this.loader = new Loader({
        apiKey: "AIzaSyBYWQU3upg_18IxJvWs7XOdgXkmhQw4Y0s",
        version: "weekly",
      });

      this.loader.load().then(async () => {
        //@ts-ignore
        const { Map } = await google.maps.importLibrary("maps");
        //@ts-ignore
        const { AdvancedMarkerElement } =
          await google.maps.importLibrary("marker");

        const map = new Map(this.$refs.map, {
          center: { lat: this.lat, lng: this.lng },
          zoom: this.lat === 0 ? 2 : 10,
          mapId: "DEMO_MAP_ID",
        });

        const draggableMarker = new AdvancedMarkerElement({
          map,
          position: { lat: this.lat, lng: this.lng },
          gmpDraggable: true,
          title: "Location marker",
        });

        draggableMarker.addListener("dragend", (event) => {
          const position = draggableMarker.position as google.maps.LatLng;
          this.lat = position.lat;
          this.lng = position.lng;

          map.panTo({ lat: this.lat, lng: this.lng });
        });
      });
    }
  },
});
