export default (form) => ({
  checkedCount: 0,
  allChecked: false,

  get checkedCountText() {
    if (this.checkedCount > 0) {
      return `${this.checkedCount} checked`;
    } else {
      return "";
    }
  },

  selectOrDeselectAll() {
    const checkboxes = this.getCheckboxes();
    let shouldCheck = false;

    if (this.indeterminate || this.checkedCount > 0) {
      // If indeterminate or any checkboxes are checked, uncheck all
      shouldCheck = false;
    } else {
      // If none are checked, check all
      shouldCheck = true;
    }

    console.log(shouldCheck);

    this.allChecked = shouldCheck;
    this.updateCheckboxes(checkboxes, shouldCheck);
    this.updateSelectAllState(false, shouldCheck);
  },

  get indeterminate() {
    return this.checkedCount > 0 && !this.allChecked;
  },

  refresh(event) {
    const checkboxes = this.getCheckboxes();
    const checkbox = event.target;
    const checkedCheckboxes = this.getCheckedCheckboxes();

    this.markRowSelected(checkbox);
    this.refreshStateFromDom();
    this.updateSelectAllState(this.indeterminate, this.allChecked);
  },

  getCheckboxes() {
    return document.querySelectorAll(
      `input[form='${this.getFormId()}'][type='checkbox']`,
    );
  },

  getCheckedCheckboxes() {
    return document.querySelectorAll(
      `input[form='${this.getFormId()}'][type='checkbox']:checked`,
    );
  },

  refreshStateFromDom() {
    this.checkedCount = this.getCheckedCheckboxes().length;
    this.allChecked = this.getCheckboxes().length === this.checkedCount;
  },

  getFormId() {
    return this.$refs.bulkActions.querySelector("form").id;
  },

  markRowSelected(checkbox) {
    const row = checkbox.closest("tr") || checkbox.closest(".box");
    if (row) {
      row.classList.toggle("selected", checkbox.checked);
    }
  },

  updateCheckboxes(checkboxes, checked) {
    checkboxes.forEach((checkbox) => {
      checkbox.checked = checked;
      this.markRowSelected(checkbox);
    });

    this.refreshStateFromDom();
  },

  bulkActions: {
    ["x-ref"]: "bulkActions",
    ["x-show"]() {
      return this.checkedCount > 0;
    },
    ["x-transition.opacity"]: true,
  },

  selectAll: {
    ["x-ref"]: "selectAll",
    ["@click"]: "selectOrDeselectAll",
  },

  updateSelectAllState(indeterminate, checked = false) {
    this.$refs.selectAll.indeterminate = indeterminate && !checked;
    this.$refs.selectAll.checked = checked;
  },
});
